<template>
  <div class="project-reservation-details">
    <div class="container fluid">
      <div class="title-section row justify-content-between card p-3 mb-2">
        <div class="col-12 sm-col-6 mb-2 sm-mb-0">
          <h2 class="mb-1">Reservation #{{ reservationId }}</h2>
          <p class="font-bold">
            <i class="fas fa-clock mr-1"></i>
            {{
              $moment(
                reservation.projectPurchaseReservation.reserveDate
              ).format("LL")
            }}
          </p>
        </div>

        <div class="col-12 sm-col-6 mb-2 sm-mb-0 text-right">
          <span class="reservation-status">
            {{ reservation.projectPurchaseReservation.status }}
          </span>
        </div>
        <div class="col-12 mt-2 text-right">
          <button
            v-if="
              reservation.projectPurchaseReservation.status === 'Pending' &&
              canManage
            "
            class="btn main"
            @click.stop="setReservationDueDate(reservation.id)"
          >
            <i class="fas fa-check mr-1" title="Approve"></i>
            Approve
          </button>
          <button
            v-if="canManage"
            class="btn danger bordered ml-1"
            title="Cancel"
            @click.stop="cancelReservation(reservation.id)"
          >
            <i class="fas fa-times mr-1"></i>
            Cancel
          </button>
        </div>
      </div>

      <agent-info class="mb-3" :agencyUser="reservation.agent"></agent-info>

      <!-- ======================== Reservation Info ======================= -->
      <reservation-info
        class="mb-2"
        :projectPurchase="reservation"
      ></reservation-info>
    </div>
    <!-- =============== Modal: Set Reserversation Due Date =============== -->
    <modal v-model="reservationDueDateModal.isShown">
      <approve-reservation
        :reservationId="reservation.id"
        @cancel="reservationDueDateModal.isShown = false"
        @submit="approveReservation"
      ></approve-reservation>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    ReservationInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationInfo"
      ),
    ApproveReservation: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ApproveReservation"
      )
  },
  mixins: [vueGoodTable],
  props: {
    reservationId: {
      type: [String, Number],
      required: true
    },
    listingRouteName: {
      type: String,
      default: "ManageProjectReservations"
    },
    canManage: {
      type: Boolean,
      default: true
    },
    getReservationDetailAPI: {
      type: Function,
      required: true
    },
    approveReservationAPI: {
      type: Function,
      required: true
    },
    cancelReservationAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      reservation: {},

      reservationDueDateModal: {
        isShown: false
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await this.getReservationDetail();
      this.$store.commit("setIsLoading", false);
    },
    async getReservationDetail() {
      try {
        let data = await this.getReservationDetailAPI(this.reservationId);
        this.reservation = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get the reservation detail. Please try again later."
        });
      }
    },
    setReservationDueDate() {
      this.reservationDueDateModal.isShown = true;
    },
    async approveReservation(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveReservationAPI(id, payload);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Reservation approved successfully."
        });

        this.reservationDueDateModal.isShown = false; // Close the approve modal

        this.$store.commit("setIsLoading", false);

        this.$router.push({
          name: this.listingRouteName,
          params: { id: this.reservation.projectUnit.id }
        });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve the reservation. Please try again later."
        });
      }
    },
    async cancelReservation(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Cancel Reservation",
        message:
          "Are you sure you want to cancel this reservation? This action cannot be undone.",
        confirmText: "Confirm"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.cancelReservationAPI(id);

          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Reservation cancelled successfully."
          });

          this.$store.commit("setIsLoading", false);

          this.$router.push({
            name: this.listingRouteName,
            params: { id: this.reservation.projectUnit.id }
          });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to cancel the reservation. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.project-reservation-details {
  .title-section {
    .reservation-status {
      background: lighten($color-secondary, 45);
      color: $color-secondary;
      padding: 6px 18px;
      border-radius: 10em;
    }
  }

  .buyer-name {
    font-weight: bold;
    font-size: 20px;
  }

  .data-container {
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 16px;
  }
}
</style>
